import React, {useState} from 'react';
import {BiCookie} from "react-icons/bi";
import {Link} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";

const Cookie = () => {
    const [accepted, setAccepted] = useState(false);

    const acceptCookie = () => {
        setAccepted(true);
        localStorage.setItem('cookieAccepted', 'true');
    };

    if (localStorage.getItem('cookieAccepted')) {
        return null;
    }

    const containerVariants = {
        hidden: {y: '100%', opacity: 0},
        visible: {
            y: 0,
            opacity: 1,
            transition: {duration: 0.5, ease: 'easeOut'}
        },
        exit: {y: '100%', opacity: 0, transition: {duration: 0.5, ease: 'easeOut'}}
    };

    return (
        <AnimatePresence>
            {!accepted && (
                <motion.div
                    className="fixed bottom-5 left-0 right-0 md:w-1/3 h-36 mx-auto rounded-xl bg-white text-zinc-500 p-4 shadow-lg z-[999]"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                >
                    <div className="flex items-center justify-between">
                        <div className="flex items-center md:w-full w-80">
                            <BiCookie className="text-amber-600 mr-2" size={96}/>
                            <span
                                className="text-sm"
                            >
                        Size daha iyi hizmet vermek için çerez kullanıyoruz. <br/>
                    <Link
                        to="/bilgiler/site-kullanim-sartlari"
                    >
                        Daha fazla bilgi için <span className="underline text-blue-700">tıklayınız.</span>
                    </Link>
                    </span>
                        </div>
                        <button
                            onClick={acceptCookie}
                            className="bg-amber-600 hover:bg-amber-600 text-white font-bold w-36 h-10 rounded-full focus:outline-none focus:shadow-outline transition duration-300"
                        >
                            Kabul Et
                        </button>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default Cookie;
