import React, {FC} from "react";
import {PostDataType} from "data/types";
import MySlider from "components/MySlider";
import CardImage from "../Card7/CardImage";


export interface SectionSliderPostsProps {
    className?: string;
    subHeading?: string;
    posts: PostDataType[];
    perView?: 2 | 3 | 4;
}

const SectionSliderPosts: FC<SectionSliderPostsProps> = ({
                                                             className = "",
                                                             posts,
                                                             perView = 4,
                                                         }) => {
    return (
        <>

            <div className={`nc-SectionSliderPosts ${className} text-black `}>
                <MySlider
                    data={posts}
                    renderItem={(item, indx) => <CardImage data={posts} key={indx} post={item}/>}
                    itemPerRow={perView}
                />
            </div>
        </>
    );
};

export default SectionSliderPosts;
