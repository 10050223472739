import { useCallback, useState } from 'react';
import axios from 'axios';
import {getCookie} from "./cookieHooks";

const useHttpClient = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const token = getCookie('t');

    const sendRequest = useCallback(async (url, method = 'GET', data = null, action = "dostbeykoz") => {
        setIsLoading(true);
        setError(null);
        try {
            data.append("token", token)
            data.append("action", action)
            const response = await axios({
                method,
                url,
                data
            });
            setIsLoading(false);
            return response.data;
        } catch (err) {
            setIsLoading(false);
            setError(err.response.data.message || 'Something went wrong!');
            throw err;
        }
    }, [token]);

    const clearError = () => {
        setError(null);
    };

    return { isLoading, error, sendRequest, clearError };
};

export default useHttpClient;