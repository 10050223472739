import { useEffect, useState } from "react";
import useHttpClient from "../hooks/useHttpClient";
import useFormData from "../hooks/useFormData";
import { fetchAPI } from "../hooks/fetchApı";
import { decodeHTMLJson } from "../utils/decodeHTML";
import Loading from "./Loading";

export default function NavbarInfo() {
    const [isHoveredWeather, setIsHoveredWeather] = useState(false);
    const [isHoveredPray, setIsHoveredPray] = useState(false);
    const [weather, setWeather] = useState({});
    const [pray, setPray] = useState({});
    const [loading, setLoading] = useState(true); // Loading başlangıçta true

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        async function fetchData() {
            try {
                const services = await fetchAPI('getServices', createFormData, sendRequest, null, null, "'weather', 'pray'");
                let prayData = {};
                let weatherData = {};

                services.forEach(service => {
                    if (service.api === "pray") {
                        prayData = {
                            updatedDate: service.ekleme_tarihi,
                            data: decodeHTMLJson(service.gelen_veri).data
                        };
                    }
                    if (service.api === "weather") {
                        weatherData = {
                            updatedDate: service.ekleme_tarihi,
                            data: decodeHTMLJson(service.gelen_veri).data
                        };
                    }
                });

                setWeather(weatherData);
                setPray(prayData);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data " + error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest]);

    const WeatherComponent = () => {
        const today = new Date().toLocaleDateString('tr-TR');
        const todayWeather = weather.data?.find(item => item.date === today);

        return (
            <>
                {weather && todayWeather && (
                    <section
                        onMouseEnter={() => setIsHoveredWeather(true)}
                        onMouseLeave={() => setIsHoveredWeather(false)}
                        className="relative"
                    >
                        <div
                            className="flex items-center justify-center gap-x-1.5 border-2 rounded-lg shadow-md md:w-32 w-full h-12 cursor-default select-none hover:opacity-70 hover:shadow-2xl transition-opacity"
                        >
                            <img src={todayWeather.icon} className="w-6" alt="weather icon" />
                            {parseInt(todayWeather.degree)} &deg;C
                        </div>
                        {isHoveredWeather && (
                            <div
                                className="p-2 bg-white shadow-lg rounded-lg w-56 absolute top-10 right-0 transition-opacity duration-500 opacity-100"
                            >
                                <ul>
                                    {weather.data?.map((weather, index) => (
                                        <li key={index}
                                            className={`flex justify-between items-center p-2 transition-all hover:bg-zinc-50 select-none ${index === 0 ? "bg-green-50 text-green-500 hover:bg-green-100" : ""}`}>
                                            <img src={weather.icon} className="w-6" alt="weather icon" />
                                            <span>{weather.day}</span>
                                            <span>{parseInt(weather.degree)} &deg;C</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </section>
                )}
            </>
        );
    };

    const PrayComponent = () => {
        const now = new Date();
        const currentTime = now.getHours() * 60 + now.getMinutes();

        let prayerTime = null;
        let prayerHours = null;

        if (pray.data) {
            for (let i = 0; i < pray.data.length; i++) {
                const currentPrayerTime = pray.data[i];
                const [hours, minutes] = currentPrayerTime.saat.split(':').map(Number);
                const currentPrayerTimeMinutes = hours * 60 + minutes;

                if (currentPrayerTimeMinutes > currentTime) {
                    prayerTime = currentPrayerTime;
                    prayerHours = currentPrayerTimeMinutes;
                    break;
                }
            }
        }

        if (!prayerTime && pray.data && pray.data[0]) {
            prayerTime = pray.data[0].vakit;
            prayerHours = pray.data[0].saat;
        }

        return (
            <section
                onMouseEnter={() => setIsHoveredPray(true)}
                onMouseLeave={() => setIsHoveredPray(false)}
                className="relative"
            >
                <div
                    className="flex flex-col justify-center items-center text-sm border-2 rounded-lg shadow-md md:w-32 w-full h-12 cursor-default select-none hover:opacity-70 hover:shadow-2xl transition-opacity"
                >
                    <p>{typeof prayerTime === "object" ? prayerTime.vakit : prayerTime}</p>
                    <p>{typeof prayerTime === "object" ? prayerTime.saat : prayerHours}</p>
                </div>
                {isHoveredPray && (
                    <div
                        className="p-2 bg-white shadow-lg rounded-lg h-64 w-56 absolute top-10 right-0 transition-opacity duration-500 opacity-100"
                    >
                        <ul>
                            {pray.data?.map((prayer, index) => (
                                <li key={index}
                                    className={`flex justify-between items-center p-2 transition-all hover:bg-zinc-50 select-none ${prayerTime?.vakit === prayer?.vakit ? "bg-green-50 text-green-500 hover:bg-green-100" : ""}`}>
                                    <span>{prayer.vakit}</span>
                                    <span>{prayer.saat}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </section>
        );
    };

    const DateTime = () => {
        const [currentTime, setCurrentTime] = useState(new Date());

        useEffect(() => {
            const interval = setInterval(() => {
                setCurrentTime(new Date());
            }, 1000);

            return () => clearInterval(interval);
        }, []);

        const formatTime = (date) => {
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        const formatDate = (date) => {
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        };

        return (
            <div
                className="flex flex-col justify-center items-center text-sm border-2 rounded-lg shadow-md md:w-32 h-12 cursor-default select-none transition-opacity w-full"
            >
                <b>{formatDate(currentTime)}</b>
                <p>{formatTime(currentTime)}</p>
            </div>
        );
    };

    return (
        <>
            {loading ? <Loading /> : (
                <div className="flex flex-col md:flex-row gap-2.5 md:items-center justify-around w-full">
                    <DateTime />
                    <WeatherComponent />
                    <PrayComponent />
                </div>
            )}
        </>
    );
}
