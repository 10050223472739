// useFormData.js
import {useCallback} from 'react';

const useFormData = () => {
    const createFormData = useCallback((data) => {
        const formData = new FormData();
        for (const key in data) {
            formData.append(key, data[key]);
        }
        return formData;
    }, []);

    return createFormData;
};

export default useFormData;