import { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import SectionAds from "../../components/Sections/SectionAds";
import BackgroundSection from "../../components/BackgroundSection/BackgroundSection";
import { fetchAPI } from "../../hooks/fetchApı";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import SectionSliderPosts from "../../components/Sections/SectionSliderPosts";
import SectionLargeSlider from "../../app/(home)/SectionLargeSlider";
import SectionMagazine2 from "../../components/Sections/SectionMagazine2";
import Loading from "../../components/Loading";
import SectionGridAuthorBox from "../../components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridPosts from "./SectionGridPosts";
import CurrencyComponent from "./CurrencyComponent";
import { decodeHTMLJson } from "../../utils/decodeHTML";
import BreakingNewsComponent from "./BreakingNewsComponent";
import { Link } from "react-router-dom";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Helmet, HelmetProvider } from "react-helmet-async";

const Home = () => {
    const [firstArea, setFirstArea] = useState([]);
    const [headlineNews, setHeadlineNews] = useState([]);
    const [allCategoryNews, setAllCategoryNews] = useState([]);
    const [videoGallery, setVideoGallery] = useState([]);
    const [economy, setEconomy] = useState({});
    const [authors, setAuthors] = useState([]);
    const [ads, setAds] = useState([]);
    const [breakingNews, setBreakingNews] = useState([]);
    const [loading, setLoading] = useState(true);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        async function fetchData() {
            try {
                const homeData = await fetchAPI('home', createFormData, sendRequest);

                const firstAreaData = homeData.firstArea.map(item => {
                    item.featuredImage = 'https://dostbeykoz.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setFirstArea(firstAreaData);

                const headlineNewsData = homeData.headlineNews.map(item => {
                    item.featuredImage = 'https://dostbeykoz.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setHeadlineNews(headlineNewsData);

                const videoGalleryData = homeData.videoGallery.map(item => {
                    item.featuredImage = 'https://dostbeykoz.com/isDosyalar/' + item.resim;
                    item.href = '/' + item.href;
                    return item;
                });
                setVideoGallery(videoGalleryData);

                const breakingNewsData = homeData.breakingNews.map(item => {
                    item.href = '/' + item.href;
                    return item;
                });
                setBreakingNews(breakingNewsData);

                setAllCategoryNews(homeData.categoryNews);

                const authorsData = await fetchAPI('authors', createFormData, sendRequest);
                authorsData.forEach(item => {
                    item.avatar = 'https://dostbeykoz.com/' + item.avatar;
                    item.avatar = item.avatar.replaceAll(".webp", ".jpg");
                    item.href = '/yazarlar/detay/' + item.href;
                });
                setAuthors(authorsData);

                const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 27);
                adsData.forEach(item => {
                    item.imgAds = 'https://dostbeykoz.com/isDosyalar/' + item.yol + "/" + item.url;
                });
                setAds(adsData);

                const services = await fetchAPI(
                    'getServices',
                    createFormData,
                    sendRequest,
                    null,
                    null,
                    "'gasoline','currency','gold','cripto'"
                );

                const currencyData = {
                    updatedDate: services[0]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[0]?.gelen_veri).data
                };

                const goldData = {
                    updatedDate: services[1]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[1]?.gelen_veri).data
                };

                const cryptoData = {
                    updatedDate: services[2]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[2]?.gelen_veri).data
                };

                const gasolineData = {
                    updatedDate: services[3]?.ekleme_tarihi,
                    data: decodeHTMLJson(services[3]?.gelen_veri).data
                };

                if (Object.keys(currencyData).length !== 0 && Object.keys(goldData).length !== 0) {
                    setEconomy({ currencyData, goldData, cryptoData, gasolineData });
                }

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data " + error);
                setLoading(false);
            }
        }
        fetchData();
    }, [createFormData, sendRequest]);

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <HelmetProvider>
                        <Helmet>
                            <title> Dost Beykoz | BEYKOZ HABER SİTESİ </title>
                            <link rel="icon" href="/images/logo-small.png" data-rh="true" />
                        </Helmet>
                    </HelmetProvider>
                    <div className="nc-PageHome relative">
                        <div className="relative">

                            {ads[0] && (
                                <div className="container">
                                    <SectionAds link={ads[0].link} imgAds={ads[0].imgAds} title={ads[0].baslik} />
                                </div>
                            )}

                            <section className="container flex flex-col gap-y-2.5">
                                {breakingNews.length > 0 && (
                                    <LazyLoad height={200} offset={100} once>
                                        <BreakingNewsComponent data={breakingNews} />
                                    </LazyLoad>
                                )}
                            </section>

                            <section className="container relative my-4">
                                <BackgroundSection />
                                <LazyLoad height={200} offset={100} once>
                                    <SectionSliderPosts
                                        posts={firstArea}
                                        card="Card11"
                                    />
                                </LazyLoad>
                            </section>

                            {ads[1] && (
                                <div className="container">
                                    <SectionAds link={ads[1].link} imgAds={ads[1].imgAds} title={ads[1].baslik} />
                                </div>
                            )}

                            <section className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100 rounded-lg py-0 md:py-20">
                                <div className="relative container">
                                    <LazyLoad height={200} offset={100} once>
                                        <SectionLargeSlider
                                            heading=""
                                            className="pt-10 pb-16 md:py-8 lg:pb-14 lg:pt-10"
                                            posts={headlineNews}
                                        />
                                    </LazyLoad>
                                </div>
                            </section>

                            <section className="container mt-4">
                                <LazyLoad height={200} offset={100} once>
                                    <CurrencyComponent data={economy} />
                                </LazyLoad>
                            </section>

                            {ads[2] && (
                                <div className="container my-12">
                                    <SectionAds link={ads[2].link} imgAds={ads[2].imgAds} title={ads[2].baslik} />
                                </div>
                            )}

                            <section className="container mt-4 relative">
                                <LazyLoad height={200} offset={100} once>
                                    <SectionGridAuthorBox
                                        authors={authors}
                                    />
                                </LazyLoad>
                            </section>

                            {ads[3] && (
                                <div className="container">
                                    <SectionAds link={ads[3].link} imgAds={ads[3].imgAds} title={ads[3].baslik} />
                                </div>
                            )}


                            {Object.keys(allCategoryNews).map((category, key) => {
                                if (key < 5) {
                                    return (
                                        <>
                                            <div className="container relative my-4" key={category}>
                                                {key % 2 === 1 && <BackgroundSection width="full" type="dark" />}
                                                <LazyLoad height={200} offset={100} once>
                                                    <SectionMagazine2
                                                        className="py-8 lg:py-12"
                                                        heading={category}
                                                        textClass={key % 2 === 1 ? "text-neutral-100" : "text-neutral-900"}
                                                        posts={allCategoryNews[category]}
                                                        showHeader={true}
                                                    />
                                                </LazyLoad>
                                                <div className="flex pb-4 justify-center items-center">
                                                    <Link to={`/kategori/${allCategoryNews[category][0].categoryLink}`}>
                                                        <ButtonPrimary className="w-96">Devamını Gör</ButtonPrimary>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <SectionAds />
                                            </div>
                                        </>
                                    )
                                }
                                return null;
                            })}


                            {ads[4] && (
                                <div className="container">
                                    <SectionAds link={ads[4].link} imgAds={ads[4].imgAds} title={ads[4].baslik} />
                                </div>
                            )}

                            <section className="dark bg-neutral-900 dark:bg-black dark:bg-opacity-20 text-neutral-100 rounded-lg">
                                <div className="relative container">
                                    <LazyLoad height={200} offset={100} once>
                                        <SectionGridPosts
                                            className="py-16 lg:py-28"
                                            headingIsCenter
                                            postCardName="card10V2"
                                            heading="Video Galeri"
                                            buttonLink="/video-galeri"
                                            subHeading=""
                                            posts={videoGallery}
                                            gridClass="md:grid-cols-2 lg:grid-cols-3"
                                        />
                                    </LazyLoad>
                                </div>
                            </section>

                            <div className="container">
                                <SectionAds />
                            </div>

                            {Object.keys(allCategoryNews).map((category, key) => {
                                if (key >= 5) {
                                    return (
                                        <>
                                            <div className="container relative my-4" key={category}>
                                                {key % 2 === 0 && <BackgroundSection width="full" type="dark" />}
                                                <LazyLoad height={200} offset={100} once>
                                                    <SectionMagazine2
                                                        className="py-8 lg:py-12"
                                                        heading={category}
                                                        textClass={key % 2 === 0 ? "text-neutral-100" : "text-neutral-900"}
                                                        posts={allCategoryNews[category]}
                                                        showHeader={true}
                                                    />
                                                </LazyLoad>
                                                <div className="flex pb-4 justify-center items-center">
                                                    <Link to={`/kategori/${allCategoryNews[category][0].categoryLink}`}>
                                                        <ButtonPrimary className="w-96">Devamını Gör</ButtonPrimary>
                                                    </Link>
                                                </div>
                                            </div>

                                            <div className="container">
                                                <SectionAds />
                                            </div>
                                        </>
                                    )
                                }
                                return null;
                            })}

                            {ads[5] && (
                                <div className="container my-4">
                                    <SectionAds link={ads[5].link} imgAds={ads[5].imgAds} title={ads[5].baslik} />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default Home;
