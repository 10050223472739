export function changeSeo(etiketler) {
    const turkceKarakterler = {
        'ç': 'c', 'Ç': 'C',
        'ğ': 'g', 'Ğ': 'G',
        'ı': 'i', 'İ': 'I',
        'ö': 'o', 'Ö': 'O',
        'ş': 's', 'Ş': 'S',
        'ü': 'u', 'Ü': 'U'
    };

    return etiketler.replace(/[çÇğĞıİöÖşŞüÜ]/g, function (match) {
        return turkceKarakterler[match];
    });
}