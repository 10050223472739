import Image from "../Image";
import { readMessage } from "../../utils/readMessage";

export default function AuthorCard({ content, isLink = true }) {
    return (
        <div
            onClick={() => readMessage(content.description)}
            className="wil-avatar flex-shrink-0 inline-flex items-center justify-center overflow-hidden rounded-full w-16 h-16 dark:ring-0 shadow-2xl z-[9999] fixed bottom-4 left-4 cursor-pointer">
            <Image
                alt="Avatar"
                src={content.avatar}
                fill
                className="object-cover"
            />
        </div>
    )
}