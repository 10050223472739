import React from "react";
import ButtonClose from "components/ButtonClose/ButtonClose";
import Logo from "components/Logo/Logo";
import {Disclosure} from "app/headlessui";
import {NavItemType} from "./NavigationItem";
import {NAVIGATION} from "data/navigation";
import SocialsList from "components/SocialsList/SocialsList";
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import Link from "components/Link";
import SearchModal from "../Header/SearchModal";
import NavbarInfo from "../NavbarInfo";

export interface NavMobileProps {
    data?: NavItemType[];
    onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({data = NAVIGATION, onClickClose}) => {
    const _renderMenuChild = (
        item: NavItemType,
        itemClass = " pl-3 text-neutral-900 dark:text-neutral-200 font-medium "
    ) => {

        return (
            <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
                {item.children?.map((i, index) => (
                    <Disclosure key={index} as="li">
                        <Link
                            href={{
                                pathname: i.href || undefined,
                            }}
                            className={`flex text-sm rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 mt-0.5 pr-4 ${itemClass}`}
                        >
              <span
                  className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                  onClick={onClickClose}
              >
                {i.name}
              </span>
                            {i.children && (
                                <span
                                    className="flex items-center flex-grow"
                                    onClick={(e) => e.preventDefault()}
                                >
                  <Disclosure.Button
                      as="span"
                      className="flex justify-end flex-grow"
                  >
                    <ChevronDownIcon
                        className="ml-2 h-4 w-4 text-slate-500"
                        aria-hidden="true"
                    />
                  </Disclosure.Button>
                </span>
                            )}
                        </Link>
                        {i.children && (
                            <Disclosure.Panel>
                                {_renderMenuChild(
                                    i,
                                    "pl-3 text-slate-600 dark:text-slate-400 "
                                )}
                            </Disclosure.Panel>
                        )}
                    </Disclosure>
                ))}
            </ul>
        );
    };

    const _renderItem = (item: NavItemType, index: number) => {
        return (
            <Disclosure
                key={index}
                as="li"
                className="text-slate-900 dark:text-white"
            >
                <Link
                    className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-slate-100 dark:hover:bg-slate-800 rounded-lg"
                    href={{
                        pathname: item.href || undefined,
                    }}
                >
          <span
              className={!item.children ? "block w-full" : ""}
              onClick={onClickClose}
          >
            {item.name}
          </span>
                    {item.children && (
                        <span
                            className="block flex-grow"
                            onClick={(e) => e.preventDefault()}
                        >
              <Disclosure.Button
                  as="span"
                  className="flex justify-end flex-grow"
              >
                <ChevronDownIcon
                    className="ml-2 h-4 w-4 text-neutral-500"
                    aria-hidden="true"
                />
              </Disclosure.Button>
            </span>
                    )}
                </Link>
                {item.children && (
                    <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
                )}
            </Disclosure>
        );
    };

    const renderSearchForm = () => {
        return (
            <>
                <div className="flex gap-x-2.5 items-center">
                    <SearchModal/>
                    <span>Arama Yap</span>
                </div>
            </>

        );
    };

    return (
        <div
            className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800">
            <div className="py-6 px-5">
                <Logo/>
                <div className="mt-5 flex justify-center items-center gap-x-5">
                    <NavbarInfo/>
                </div>

                <span className="absolute right-2 top-2 p-1"> <ButtonClose onClick={onClickClose}/> </span>

                <div className="mt-5">{renderSearchForm()}</div>
            </div>
            <ul className="flex flex-col py-6 px-2 space-y-1">
                {data.map(_renderItem)}
            </ul>
            <div className="flex flex-col mt-5 text-slate-600 dark:text-slate-300 text-sm">
                <div className="flex justify-between items-center mt-4">
                    <SocialsList
                        itemClass="w-7 h-7 sm:w-8 sm:h-8 flex items-center justify-center rounded-full text-xl"/>
                </div>
            </div>
        </div>
    );
};

export default NavMobile;
