// import { setCookie } from 'hooks/cookieHooks';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export default function Schema({ content, gallery = [], children }) {

    if (!content) {
        return null;
    }

    function degistirMainImage(imageUrl) {
        let yeniUrl = imageUrl.replace(/\.webp$/, '.jpg');
        yeniUrl = yeniUrl.replace(/galeri_/, '');
        return yeniUrl;
    }

    let mainImage = content?.featuredImage || (gallery.length > 0 && gallery[0]?.featuredImage) || 'https://dostbeykoz.com/logo.png';

    mainImage = degistirMainImage(mainImage);

    const schema = {
        "@context": "https://schema.org/",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": window.location.href
        },
        "headline": content.title,
        "description": content.spot,
        "articleBody": content.detay,
        "datePublished": content.publishedDate,
        "dateModified": content.editDate,
        "author": {
            "@type": "Organization",
            "name": content.author || 'Haber Merkezi'
        },
        "publisher": {
            "@type": "Organization",
            "name": content.publisher || 'Dost Beykoz',
            "logo": {
                "@type": "ImageObject",
                "url": content.publisherLogo || 'https://dostbeykoz.com/logo.png'
            }
        },
        "image": {
            "@type": "ImageObject",
            "url": mainImage,
            "width": 800,
            "height": 600
        },
        "keywords": content.etiketler ? content.etiketler.split(',') : [],
        "articleSection": content.category,
        "interactionStatistic": {
            "@type": "InteractionCounter",
            "interactionType": {
                "@type": "http://schema.org/CommentAction"
            },
            "userInteractionCount": content.totalComment || '0'
        }
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{content?.title}</title>
                <meta name="description" content={content?.spot} />
                <meta name="keywords" content={content?.title} />
                <meta itemprop="publisher" content="DOSTBEYKOZ" />
                <meta name="author" content={content?.author || 'Haber Merkezi'} />
                <link rel='canonical' href={window.location.href} />

                <meta name="robots" content="FOLLOW, INDEX" />
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />

                <meta name="msapplication-starturl" content={window.location.href} />

                {/* Twitter Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:creator" content={content?.author || 'Haber Merkezi'} />
                <meta name="twitter:title" content={content?.title} />
                <meta name="twitter:description" content={content?.spot} />

                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={content?.title} />
                <meta property="og:description" content={content?.spot} />
                <meta property="og:image" content={mainImage} />
                <meta property="og:type" content="article" />
                <meta property="og:image:width" content="800" />
                <meta property="og:image:height" content="600" />
                <meta property="og:site_name" content={content?.publisher || 'Dost Beykoz'} />
                <link rel='image_src' href={mainImage} />
                <meta name="msapplication-window" content="width=1024;height=768" />

                <link rel="alternate" type="application/rss+xml" title={content?.title}
                    href="https://www.dostbeykoz.com/sitemap.xml" />

                <link rel="alternate" type="application/rss+xml" title={content?.title}
                    href="https://www.dostbeykoz.com/news_sitemap.xml" />

                <link rel="shortcut icon" href="/logo-small.png" type="image/x-icon" />

                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <base url="https://www.dostbeykoz.com" />

                <script type="application/ld+json">{JSON.stringify(schema)}</script>
            </Helmet>

            {children}
        </HelmetProvider>
    )
}
