import React, {FC, useState} from "react";
import {PostDataType} from "data/types";
import Image from "components/Image";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export interface Card7Props {
    className?: string;
    post: PostDataType;
    hoverClass?: string;
    ratio?: string;
    data?: any[];
}

const CardImage: FC<Card7Props> = ({
                                       className = "h-full",
                                       ratio = "aspect-w-6 aspect-h-7 sm:aspect-h-8",
                                       post,
                                       hoverClass = "",
                                       data,
                                   }) => {
    const {featuredImage} = post;
    const [open, setOpen] = useState(false);

    const slides = data?.map((item: any) => ({
        src: item.featuredImage,
        width: 3840,
        height: 2560,
        srcSet: [{src: item.featuredImage, width: 128, height: 128}],
    })) || [];

    return (
        <>
            <Lightbox
                open={open}
                close={() => setOpen(false)}
                slides={slides}
                plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom]}
            />

            <div
                onClick={() => setOpen(true)}
                className={`nc-Card7 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${hoverClass} ${className}`}
            >
                <div
                    className="absolute inset-0 x-0 top-0 p-3  flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300"></div>
                <div className={`flex items-start relative w-full ${ratio}`}>
                    <Image
                        fill
                        alt=""
                        sizes="(max-width: 600px) 480px,800px"
                        className="object-cover w-full h-full rounded-3xl "
                        src={featuredImage}
                    />
                    <span
                        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"></span>
                </div>
            </div>
        </>
    );
};

export default CardImage;
