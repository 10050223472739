import Swal from 'sweetalert2'

export function readMessage(content) {

    let text = new DOMParser().parseFromString(content, 'text/html').body.textContent
    text = text.replace("&nbps;", "");

    const swalWithCustomButtons = Swal.mixin({
        customClass: {
            confirmButton: "h-10 bg-indigo-50 text-indigo-600 mx-2 px-8 text-xl rounded-md hover:bg-indigo-100 !outline-indigo-500 border-2 !border-indigo-100",
        },
        buttonsStyling: false
    });

    swalWithCustomButtons.fire({
        title: "Yazar Bilgisi",
        text: text,
        showCancelButton: false,
        confirmButtonText: "Kapat",
        reverseButtons: true,
    })
    return false;
}