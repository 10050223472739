import { FC, Fragment, ReactNode, useEffect, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { FiSearch } from "react-icons/fi";
import { ImSpinner9 } from "react-icons/im";
import { getCookie } from "../../hooks/cookieHooks";
import axios from "axios";

interface SearchResult {
    baslik: string;
    seo_link: string;
}

interface Props {
    renderTrigger?: () => ReactNode;
}

const SearchModal: FC<Props> = ({ renderTrigger }) => {
    const [open, setOpen] = useState(false);
    const [rawQuery, setRawQuery] = useState("a");
    const [loading, setLoading] = useState(false);
    const [searchData, setSearchData] = useState<SearchResult[]>([]);

    const router = useNavigate();

    useEffect(() => {
        setSearchData([]);
        if (rawQuery.length >= 3) setLoading(true)
        const debounceTimer = setTimeout(() => {
            const query = rawQuery.toLowerCase().replace(/^[#>]/, "");

            if (query.length >= 3) {
                const token = getCookie("t");
                const formData = new FormData();
                formData.append("action", "dostbeykoz");
                formData.append("page", "search");
                formData.append("token", token);
                formData.append("query", query);

                axios
                    .post("https://www.dostbeykoz.com/api/index.php", formData)
                    .then((res) => {
                        setSearchData(res.data.data);
                        setLoading(false);
                    })
                    .catch((err) => {
                        console.error(err);
                        setLoading(false);
                    });
            }
        }, 1500);

        return () => clearTimeout(debounceTimer);
    }, [rawQuery]);

    return (
        <>
            <div onClick={() => setOpen(true)} className="cursor-pointer">
                {renderTrigger ? (
                    renderTrigger()
                ) : (
                    <button
                        className="flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                    >
                        <FiSearch size={20} />
                    </button>
                )}
            </div>

            <Transition.Root show={open} as={Fragment} afterLeave={() => setRawQuery("a")} appear>
                <Dialog as="div" className="relative z-[99]" onClose={() => setOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/40 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-100"
                        >
                            <Dialog.Panel
                                className="block mx-auto max-w-2xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
                                as="form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    router("/search");
                                    setOpen(false);
                                }}
                            >
                                <Combobox
                                    onChange={(item: any) => {
                                        router(item.href);
                                        setOpen(false);
                                    }}
                                    name="searchpallet"
                                >
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                                            placeholder="Ara"
                                            onChange={(event) => setRawQuery(event.target.value)}
                                        />
                                    </div>

                                    <div className="grid grid-cols-12 gap-1">
                                        {loading && (
                                            <div className="col-span-12 mx-auto py-4">
                                                <ImSpinner9 size={30} className="animate-spin" />
                                            </div>
                                        )}

                                        {!loading && (
                                            <>
                                                {searchData && searchData.length > 0 ? (
                                                    searchData.map((item, key) => (
                                                        <Link
                                                            to={`/${item.seo_link}`}
                                                            key={key}
                                                            onClick={() => setOpen(false)}
                                                            className="col-span-12 hover:bg-zinc-100 rounded px-4 py-2"
                                                        >
                                                            {item.baslik}
                                                        </Link>
                                                    ))
                                                ) : (
                                                    <p className="col-span-12 mx-auto py-2">Sonuç yok</p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Combobox>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default SearchModal;
