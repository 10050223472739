import {FC} from "react";
import {PostDataType} from "data/types";
import Link from "components/Link";
import Image from "components/Image";
import {handleHit} from "../../utils/handleHit";

export interface Card7Props {
    className?: string;
    post: PostDataType;
    hoverClass?: string;
    ratio?: string;
}

const Card7: FC<Card7Props> = ({
                                   className = "h-full",
                                   ratio = "aspect-w-6 aspect-h-7 sm:aspect-h-8",
                                   post,
                                   hoverClass = "",
                               }) => {
    const {
        title,
        href,
        featuredImage,
        kod
    } = post;

    return (
        <div
            onClick={() => handleHit(kod)}
            className={`nc-Card7 relative flex flex-col group rounded-3xl overflow-hidden z-0 ${hoverClass} ${className}`}
        >
            <div
                className="absolute inset- x-0 top-0 p-3  flex items-center justify-between transition-all opacity-0 z-[-1] group-hover:opacity-100 group-hover:z-10 duration-300">

            </div>
            <Link href={href} className={`flex items-start relative w-full ${ratio}`}>
                <Image
                    fill
                    alt=""
                    sizes="(max-width: 600px) 480px,800px"
                    className="object-cover w-full h-full rounded-3xl "
                    src={featuredImage}
                />

                <span
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 group-hover:opacity-100 transition-opacity"></span>
            </Link>

            {title && (
                <div
                    className="absolute bottom-3 inset-x-3 p-4 bg-white dark:bg-neutral-900 flex flex-col flex-grow rounded-3xl group-hover:shadow-2xl transition-shadow">
                    <Link href={href} className="absolute inset-0"></Link>
                    <div className="space-y-2.5 mb-3">
                        <h2 className="block text-base font-semibold text-neutral-900 dark:text-neutral-100 ">
                            <Link href={href} title={title} className="line-clamp-2">
                                {title}
                            </Link>
                        </h2>
                    </div>
                </div>
            )}

        </div>
    );
};

export default Card7;
