import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SettingsState {
    adres: string;
    facebook: string;
    ds1: string;
    twitter:string;
    youtube:string;
    aciklama:string;
}

const initialState: {} = {
};

const settings = createSlice({
    name: "settings",
    initialState,
    reducers: {
        setSettingsRed: (state, action: PayloadAction<SettingsState>) => {
            return action.payload;
        },
    },
});

export const { setSettingsRed } = settings.actions;
export default settings.reducer;
