const Loading = () => {
    return (
        <div className="flex items-center justify-center h-screen">
            <img
                src="/images/loading-full.png"
                alt="loading"
                className="animate-ping"
            />
        </div>
    );
};
export default Loading;
