import React, {FC} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../stores/rootReducer";
import {FaFacebook, FaInstagram, FaXTwitter, FaYoutube} from "react-icons/fa6";
import {Link} from "react-router-dom";

export interface SocialsList1Props {
    className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({className = "space-y-2.5"}) => {
    const settings = useSelector((state: RootState) => state.settings);

    const socials = [
        {id: 1, title: 'Instagram', href: settings.ds1, icon: <FaInstagram/>},
        {id: 2, title: 'Facebook', href: settings.facebook, icon: <FaFacebook/>},
        {id: 3, title: 'Twitter', href: settings.twitter, icon: <FaXTwitter/>},
        {id: 4, title: 'Youtube', href: settings.youtube, icon: <FaYoutube/>},
    ];

    return (
        <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
            {socials.map((social) => (
                <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    key={social.id}
                    to={social.href}
                    className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-3 "
                >
                    <div>{social.icon}</div>
                    <span className="hidden lg:block text-sm">{social.title}</span>
                </Link>
            ))}
        </div>
    );
};

export default SocialsList1;
