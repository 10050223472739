export function formatDateTime(dateTimeString: string, format:string = "y/m/d") {
    const date = new Date(dateTimeString);
    const day = date.getDate().toString().padStart(2, '0'); // Gün
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Ay (0-11 arası olduğu için +1)
    const year = date.getFullYear(); // Yıl
    const hours = date.getHours(); // saat
    const minute = date.getMinutes(); // dakika

    switch(format) {
        case "y/m/d":
        return `${day}/${month}/${year}`;
        case "y/m/d h:i":
        return `${day}/${month}/${year} ${hours}:${minute}`;
        default:
        return `${day}/${month}/${year}`;
    }
}