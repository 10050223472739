import { NavItemType } from "components/Navigation/NavigationItem";
import { Route } from "routers/types";
import _ from "lodash";
import axios from "axios";
import { getCookie } from "../hooks/cookieHooks";

const randomId = _.uniqueId;

// API'den veri çekmek için bir fonksiyon
const fetchData = async () => {
  try {
    const formData = new FormData();
    const token = getCookie('t');
    formData.append("token", token);
    formData.append("action", "dostbeykoz");
    formData.append("page", "menu");
    const response = await axios.post('https://dostbeykoz.com/api/index.php', formData);
    return response.data.data;
  } catch (error) {
    console.error("Veri çekme hatası:", error);
    return [];
  }
};

// Veriyi çekip işleyen fonksiyon
const processApiData = (apiData: any[]): NavItemType[] => {
  return apiData.map((item) => ({
    id: randomId(),
    href: 'kategori/' + item.seo_link as Route,
    name: item.baslik,
    type: item?.type,
  }));
};

// Verileri API'den çekip NAVIGATION'u güncelleyen fonksiyon
const updateNavigation = async () => {
  const apiData = await fetchData();
  return processApiData(apiData);
};

const children = await updateNavigation()
  .then(navData => navData);

export const NAVIGATION: NavItemType[] = [
  {
    id: randomId(),
    href: "/",
    name: "Haberler",
    type: "dropdown",
    children: children
  },
  {
    id: randomId(),
    href: "/kategori/bolgesel-haberler" as Route,
    name: "Bölgesel",
  },
  // {
  //   id: randomId(),
  //   href: "/kategori/spor" as Route,
  //   name: "Spor",
  // },
  {
    id: randomId(),
    href: "/yazarlar" as Route,
    name: "Yazarlar",
  },
];

