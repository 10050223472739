import React, { FC } from "react";

export interface BackgroundSectionProps {
  className?: string;
  type?: string;
  width?: string;
}

const BackgroundSection: FC<BackgroundSectionProps> = ({
  type,
  width,
  className = `${type === "dark" ? "bg-neutral-900" : "bg-neutral-100"} dark:bg-black dark:bg-opacity-20`,
}) => {
  return (
    <div
      className={`absolute inset-y-0 w-screen ${width === "full" ? '' : 'xl:max-w-[1340px] 2xl:max-w-screen-2xl xl:rounded-[40px]'} left-1/2 transform -translate-x-1/2  z-0 ${className}`}
    >
      <span className="sr-only hidden">bg</span>
    </div>
  );
};

export default BackgroundSection;
