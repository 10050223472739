export function getCookie(cookieName) {
    const cookies = document.cookie.split(';').map(cookie => cookie.split('=')).reduce((acc, [key, value]) => ({
        ...acc,
        [decodeURIComponent(key.trim())]: decodeURIComponent(value)
    }), {});
    return cookies[cookieName];
}

export function setCookie(cookieName, cookieValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);

    const cookieOptions = {
        expires: expirationDate.toUTCString(),
        path: '/'
    };

    document.cookie = `${encodeURIComponent(cookieName)}=${encodeURIComponent(cookieValue)}; ${Object.entries(cookieOptions).map(([key, value]) => `${key}=${value}`).join('; ')}`;
}

export function deleteCookie(cookieName) {
    // Geçmiş bir tarih olarak ayarlanmış bir cookie silinir.
    document.cookie = `${encodeURIComponent(cookieName)}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}