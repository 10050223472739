import React, { FC, ReactNode } from "react";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import ButtonPrimary from "components/Button/ButtonPrimary";
import Card16Podcast from "../../components/Card16Podcast/Card16Podcast";
import { Link } from "react-router-dom";

export interface SectionGridPostsProps {
    posts?: PostDataType[];
    className?: string;
    gridClass?: string;
    heading?: ReactNode;
    subHeading?: ReactNode;
    headingIsCenter?: boolean;
    buttonLink?: string;
}

const SectionGridPosts: FC<SectionGridPostsProps> = ({
    posts = [],
    className = "",
    gridClass = "",
    heading,
    subHeading,
    headingIsCenter,
    buttonLink = "/"
}) => {

    return (
        <div className={`nc-SectionGridPosts relative ${className}`}>
            <Heading desc={subHeading} isCenter={headingIsCenter}>
                {heading}
            </Heading>
            <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
                {posts.map((post, key) => (
                    <Card16Podcast key={key} post={post} />
                ))}
            </div>
            <div className="flex mt-20 justify-center items-center">
                <Link to={buttonLink}>  <ButtonPrimary>Devamını Gör</ButtonPrimary></Link>
            </div>
        </div>
    );
};

export default SectionGridPosts;
