import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import PostCardCommentBtn from "../../components/PostCardCommentBtn/PostCardCommentBtn";
import { ArrowUpIcon } from "@heroicons/react/24/solid";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import SingleCommentForm from "../article/SingleCommentForm";
import SingleCommentLists from "../article/SingleCommentLists";
import useHttpClient from "../../hooks/useHttpClient";
import useFormData from "../../hooks/useFormData";
import { fetchAPI } from "../../hooks/fetchApı";
import Loading from "../../components/Loading";
import { getCookie } from "../../hooks/cookieHooks";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import HTMLFlipBook from "react-pageflip";
import Schema from "../../components/Schema";
import SectionAds from "components/Sections/SectionAds";

export default function PublicationsDetails() {
    const params = useParams();
    const endedAnchorRef = useRef(null);
    const contentRef = useRef(null);
    const progressRef = useRef(null);
    const [width, setWidth] = useState(800);
    const [height, setHeight] = useState(1000);

    const [loading, setLoading] = useState(true);
    const [trigger, setTrigger] = useState(false);
    const [comments, setComments] = useState([]);
    const [gallery, setGallery] = useState([]);
    const [ads, setAds] = useState([]);

    const { sendRequest } = useHttpClient();
    const createFormData = useFormData();

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        if (window.innerWidth < 800) {
            handleResize()
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    useEffect(() => {
        async function fetchData() {
            try {
                const data = await fetchAPI('newspapersDetails', createFormData, sendRequest, null, null, params.slug);
                const { gallery } = data;
                setComments(data.comments);

                gallery.forEach(item => {
                    item.featuredImage = 'https://dostbeykoz.com/isDosyalar/' + item.yol + "/" + item.url;
                    item.featuredImage = item.featuredImage.replace(/\.webp/g, ".jpg");
                });

                const adsData = await fetchAPI('getAds', createFormData, sendRequest, null, null, 40);
                adsData.forEach(item => {
                    item.imgAds = 'https://dostbeykoz.com/isDosyalar/' + item.yol + "/" + item.url;
                });
                setAds(adsData);

                setGallery(gallery);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                setLoading(false);
            }
        }

        fetchData();
    }, [createFormData, sendRequest, params.slug, trigger]);

    const [isShowScrollToTop, setIsShowScrollToTop] = useState(false);

    const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
        threshold: 0,
        root: null,
        rootMargin: "0%",
        freezeOnceVisible: false,
    });
    const totalComment = comments.length;
    useEffect(() => {
        const handleProgressIndicator = () => {
            const entryContent = contentRef.current;
            const progressBarContent = progressRef.current;

            if (!entryContent || !progressBarContent) {
                return;
            }

            const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
            let winScroll = document.body.scrollTop || document.documentElement.scrollTop;
            let scrolled = (winScroll / totalEntryH) * 100;

            progressBarContent.innerText = scrolled.toFixed(0) + "%";

            if (scrolled >= 100) {
                setIsShowScrollToTop(true);
            } else {
                setIsShowScrollToTop(false);
            }
        };

        const handleProgressIndicatorHeadeEvent = () => {
            window?.requestAnimationFrame(handleProgressIndicator);
        };
        handleProgressIndicator();
        window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
        return () => {
            window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
        };
    }, []);

    const showLikeAndCommentSticky =
        !endedAnchorEntry?.intersectionRatio &&
        (endedAnchorEntry?.boundingClientRect.top || 0) >= 0;

    const pageCard = () => {
        return (
            <div
                className={`sticky mt-8 bottom-8 !z-50 justify-center ${showLikeAndCommentSticky ? "flex" : "hidden"
                    }`}
            >
                <div
                    className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>
                    <PostCardCommentBtn
                        commentCount={totalComment}
                        isATagOnSingle
                        className={` flex px-3 h-9 text-xs`}
                    />
                    <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

                    <button
                        className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${isShowScrollToTop ? "flex" : "hidden"
                            }`}
                        onClick={() => {
                            window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                    >
                        <ArrowUpIcon className="w-4 h-4" />
                    </button>

                    <button
                        ref={progressRef}
                        className={`w-9 h-9 items-center justify-center ${isShowScrollToTop ? "hidden" : "flex"
                            }`}
                        title="Go to top"
                    >
                        %
                    </button>
                </div>
            </div>
        )
    }


    const commentSubmit = async (values) => {
        const token = getCookie('t');
        const formData = new FormData();
        formData.append("token", token);
        formData.append("action", "dostbeykoz");
        formData.append("page", "postComment");

        formData.append("name_surname", values.name_surname);
        formData.append("email", values.email);
        formData.append("comment", values.comment);

        const { error, message } = await axios.post("https://dostbeykoz.com/api/index.php", formData)
            .then(res => res.data)
            .catch(err => console.error(err))

        if (error) {
            const messages = message.split("\n");
            messages.forEach(message => {
                if (message !== "") toast.error(message);
            })
        } else {
            toast.success(message)
            setTrigger(!trigger)
        }
    }

    const renderContent = () => {
        return (
            <>
                <Toaster />
                <SectionAds link={ads[0]?.link} imgAds={ads[0]?.imgAds} title={ads[0]?.baslik} />

                <Schema content={gallery} gallery={gallery} />
                <div className="relative">
                    <div className="nc-SingleContent space-y-10">
                        <div
                            id="single-entry-content"
                            className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                            ref={contentRef}
                        >

                        </div>
                        <div
                            className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>

                        <div id="comments" className="scroll-mt-20 max-w-screen-md mx-auto pt-5">
                            <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                                Yorumlarınızla Katkı Sağlayın
                            </h3>

                            <SingleCommentForm onClickSubmit={commentSubmit} />
                        </div>

                        <div className="max-w-screen-md mx-auto">
                            <SingleCommentLists totalComment={totalComment} comments={comments} />
                            <div ref={endedAnchorRef}></div>
                        </div>
                    </div>
                    {pageCard()}
                </div>
            </>
        )
    }


    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <>
                    <div className="grid grid-rows-1 my-3 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-12">
                        <SectionAds link={ads[1]?.link} imgAds={ads[1]?.imgAds} title={ads[1]?.baslik} />

                        <HTMLFlipBook
                            width={width}
                            height={height}
                            autoSize={true}
                            className="col-span-12 mx-auto"
                        >
                            {gallery.map((page, key) => (
                                <img key={key} src={page.featuredImage} alt="" />
                            ))}
                        </HTMLFlipBook>
                        <SectionAds link={ads[2]?.link} imgAds={ads[2]?.imgAds} title={ads[2]?.baslik} />

                    </div>

                    <div className="container mt-10 max-w-7xl relative">
                        {renderContent()}
                    </div>
                </>
            )}
        </>
    )
}